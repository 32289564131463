(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

/*
    ----- MARKETO -----
*/
// window.addEventListener('hashchange', function () {
//     if (window.location.href.indexOf("faas_unique_submission_id") > -1) {
      
//         $('.form-section').addClass('successful');
//         $('.download').removeClass('gated');
//         $('.video').removeClass('gated');


//     }
//     console.log('location changed!');
// });


$(document).ready(function(){

    $('form input').each(function(){
        var labelText = $(this).parent().children('label').text();

        $(this).attr('placeholder', '*'+labelText);

    });

    $('form input[type=checkbox]').parent().addClass('checkbox');
    $('form select').parent().parent().addClass('select');


    $('.formButton').on('click', function(event){
        event.preventDefault();
        $('html, body').animate({
            scrollTop: $('#form').offset().top
        }, 500);
    });

    $('.feds-navList-hamburger').on('click', function(){
        $(this).toggleClass('active');
        $('.subnav').toggleClass('active');
        $('.top-levelNav').toggleClass('active');
        $('.logoAndNav').toggleClass('active');
        $('.masthead3').toggleClass('active');
    })

    var mastheadHeight = $('.masthead3').outerHeight();
    $('header').css('margin-top', mastheadHeight);


    $('.explore').on('click', function(){
        $(this).toggleClass('active');
        $('.nav-popup').toggleClass('active');
    });

    $('.form-section .submitButton').on('click', function(){
        $('.video').removeClass('gated');
        $('.download').removeClass('gated');
        console.log('gated removed');
    });

    $('.feds-navLink').each(function(){
        
        $(this).on('click', function(){

            if ( $(this).parent().parent().hasClass('feds-dropdown--active') ) {
                $(this).parent().parent().removeClass('feds-dropdown--active');
                $(this).parent().children('.feds-popup').removeClass('feds-popup--open');
                $(this).attr('aria-expanded', 'false');
                $(this).attr('daa-lh', 'header|Open');
            } else {
                $('.feds-popup').removeClass('feds-popup--open');
                $('.feds-navList-item').removeClass('feds-dropdown--active');
                $('.feds-navLink').attr('aria-expanded', 'false');
                $('.feds-navLink').attr('daa-lh', 'header|Open');
                $(this).parent().parent().addClass('feds-dropdown--active');
                $(this).parent().children('.feds-popup').addClass('feds-popup--open');
                $(this).attr('aria-expanded', 'true');
                $(this).attr('daa-lh', 'header|Close');
            }

        });

    });

    $('.level2').each(function() {

        $(this).on('click', function(){

            
            if ($(this).hasClass('feds-dropdown--active')) {

                $(this).removeClass('feds-dropdown--active');
                $(this).children().children('.feds-navList-headline').attr('aria-expanded', 'false');
                $(this).children('.feds-navList-wrapper').children().children().children().children('.feds-navList-headline').attr('aria-expanded', 'false');

            } else {
                
                $('.level2').removeClass('feds-dropdown--active');
                $('.level2').children().children('.feds-navList-headline').attr('aria-expanded', 'false');
                $('.level2').children('.feds-navList-wrapper').children().children().children().children('.feds-navList-headline').attr('aria-expanded', 'false');

                $(this).addClass('feds-dropdown--active');
                $(this).children().children('.feds-navList-headline').attr('aria-expanded', 'true');
                $(this).children('.feds-navList-wrapper').children().children().children().children('.feds-navList-headline').attr('aria-expanded', 'true');
            }
            

        });

    });

    $('.feds-navList-hamburger').on('click', function(){

        console.log('button clicked');

        if ($('.feds-navList-hamburger').attr('aria-expanded') == 'false') {
            $('.feds-navList-hamburger').attr('aria-expanded', 'true');
        }
        else {
            $('.feds-navList-hamburger').attr('aria-expanded', 'false');
        }

    });

    $(function($) {
        var currentMousePos = { x: -1, y: -1 };
        $(window).mousemove(function(event) {
            currentMousePos.x = event.clientX;
            currentMousePos.y = event.clientY;
        });

        $(window).on('click', function(){
            console.log('mouse Xpos: ' + currentMousePos.x);
            console.log('mouse Ypos: ' + currentMousePos.y);
        });

    });

    var winWidth = $(window).width();

    if (winWidth <= 1199) {

        $('.feds-popup').removeClass('feds-popup--open');
        $('.feds-navList-item').removeClass('feds-dropdown--active');
        $('.feds-navLink').attr('aria-expanded', 'false');
        $('.feds-navLink').attr('daa-lh', 'header|Open');


    }

    if (winWidth <= 940) {
        $('.nav-popup .inner .title').on('click', function(){

            if (!$(this).hasClass('active')) {
                $('.nav-popup .inner .title').removeClass('active');
                $('.nav-popup .inner .dropList').removeClass('active');
                $(this).parent().children('.dropList').addClass('active');
                $(this).addClass('active');
            } else {
                $('.nav-popup .inner .title').removeClass('active');
                $('.nav-popup .inner .dropList').removeClass('active');
            }
            
            
        });
    }



    var player1 = videojs('video1', {
                        controls: false,
                        preload: 'auto'
                    });
    // var player2 = videojs('video2', {
    //                     controls: false,
    //                     preload: 'auto'
    //                 });
    // var player3 = videojs('video3', {
    //                     controls: false,
    //                     preload: 'auto'
    //                 });

    var player1a = videojs('video1a', {
                        controls: true,
                        preload: 'auto'
                    });
    // var player2a = videojs('video2a', {
    //                     controls: true,
    //                     preload: 'auto'
    //                 });
    // var player3a = videojs('video3a', {
    //                     controls: true,
    //                     preload: 'auto'
    //                 });
    // var player4a = videojs('video4a', {
    //                     controls: true,
    //                     preload: 'auto'
    //                 });
    // var player5a = videojs('video5a', {
    //                     controls: true,
    //                     preload: 'auto'
    //                 });
    // var player6a = videojs('video6a', {
    //                     controls: true,
    //                     preload: 'auto'
    //                 });

    var podcastParams = {
            controls: true,
            autoplay: false,
            loop: false,
            muted: false,
            fluid: true,
            plugins: {
                wavesurfer: {
                    backend: 'MediaElement',
                    debug: false,
                    displayMilliseconds: false,
                    waveColor: '#ffffff',
                    progressColor: '#FCF990',
                    cursorColor: '#FCFC42',
                    hideScrollbar: true
                }
            }
        };
    var podcastParams2 = {
            controls: true,
            autoplay: false,
            loop: false,
            muted: false,
            fluid: true,
            plugins: {
                wavesurfer: {
                    backend: 'MediaElement',
                    debug: false,
                    displayMilliseconds: false,
                    waveColor: '#ffffff',
                    progressColor: '#FCF990',
                    cursorColor: '#FCFC42',
                    hideScrollbar: true
                }
            }
        };
    var podcastParams3 = {
            controls: true,
            autoplay: false,
            loop: false,
            muted: false,
            fluid: true,
            plugins: {
                wavesurfer: {
                    backend: 'MediaElement',
                    debug: false,
                    displayMilliseconds: false,
                    waveColor: '#ffffff',
                    progressColor: '#FCF990',
                    cursorColor: '#FCFC42',
                    hideScrollbar: true
                }
            }
        };


    
    $('.video-slider1').slick({
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: true,
      infinite:false,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true
          }
        }
      ]
    });

    $('.video-slider2').slick({
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: true,
      infinite:false,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true
          }
        }
      ]
    });

    $('.video-slider3').slick({
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: true,
      infinite:false,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true
          }
        }
      ]
    });

    $('.download-slider-1').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      infinite:false,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 440,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
    $('.download-slider-2').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      infinite:false,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 440,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    $('.download-slider-3').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      infinite:false,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 440,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    $('.video-slider').on('breakpoint', function() {

        $('.video').on('mouseover', function(){

            $(this).parent().parent().addClass('active');

            if ($(this).hasClass('video1') && !$(this).hasClass('pause')) { player1.play(); } 
            if ($(this).hasClass('video2') && !$(this).hasClass('pause') && !$(this).hasClass('gated')) { player2.play(); }
            if ($(this).hasClass('video3') && !$(this).hasClass('pause') && !$(this).hasClass('gated')) { player3.play(); }
            if ($(this).hasClass('video4') && !$(this).hasClass('pause') && !$(this).hasClass('gated')) { player4.play(); }
            if ($(this).hasClass('video5') && !$(this).hasClass('pause') && !$(this).hasClass('gated')) { player5.play(); }
            // if ($(this).hasClass('video6') && !$(this).hasClass('pause') && !$(this).hasClass('gated')) { player6.play(); }
        });

        $('.video').on('mouseout', function(){

            $(this).parent().parent().removeClass('active');

            if ($(this).hasClass('video1')) {
                player1.pause();
                if ($(this).children().children('.unmute').hasClass('mute')) { player1.muted(true); } 
                else { player1.muted(false); }
            } 
            if ($(this).hasClass('video2')) {
                player2.pause();
                if ($(this).children().children('.unmute').hasClass('mute')) { player2.muted(true); } 
                else { player2.muted(false); }
            }
            if ($(this).hasClass('video3')) {
                player3.pause();
                if ($(this).children().children('.unmute').hasClass('mute')) { player3.muted(true); } 
                else { player3.muted(false); }
            }
            if ($(this).hasClass('video4')) {
                player4.pause();
                if ($(this).children().children('.unmute').hasClass('mute')) { player4.muted(true); } 
                else { player4.muted(false); }
            }
            if ($(this).hasClass('video5')) {
                player5.pause();
                if ($(this).children().children('.unmute').hasClass('mute')) { player5.muted(true); } 
                else { player5.muted(false); }
            }
            // if ($(this).hasClass('video6')) {
            //     player6.pause();
            //     if ($(this).children().children('.unmute').hasClass('mute')) { player6.muted(true); } 
            //     else { player6.muted(false); }
            // }
        });

        $('.enlarge').on('click', function(event){
            var currentMousePos = { x: -1, y: -1 };   
            currentMousePos.x = event.clientX;
            currentMousePos.y = event.clientY;

            if ($(this).parent().parent().hasClass('video1')) {
                
                $('.modals .modal-video1').css('left', currentMousePos.x);
                $('.modals .modal-video1').css('top', currentMousePos.y);

                setTimeout(function greet() {
                    $( ".modals .modal-video1" ).animate({left: '0px',top: "0px"}, 100, function() {
                        // Animation complete.
                        });
                    $('.modals .modal-video1').addClass('active');
                }, 100);
            }


            // if ($(this).parent().parent().hasClass('video2')) {
            //     $('.modals .modal-video2').css('left', currentMousePos.x);
            //     $('.modals .modal-video2').css('top', currentMousePos.y);

            //     setTimeout(function greet() {
            //         $( ".modals .modal-video2" ).animate({left: '0px',top: "0px"}, 100, function() {
            //             // Animation complete.
            //           });
            //         $('.modals .modal-video2').addClass('active');
            //     }, 100);
            // };

            // if ($(this).parent().parent().hasClass('video3')) {
            //     $('.modals .modal-video3').css('left', currentMousePos.x);
            //     $('.modals .modal-video3').css('top', currentMousePos.y);

            //     setTimeout(function greet() {
            //         $( ".modals .modal-video3" ).animate({left: '0px',top: "0px"}, 100, function() {
            //             // Animation complete.
            //           });
            //         $('.modals .modal-video3').addClass('active');
            //     }, 100);
            // };
            
        });

        $('.download').on('click', function(event){
            var currentMousePos = { x: -1, y: -1 };   
            currentMousePos.x = event.clientX;
            currentMousePos.y = event.clientY;

            if ($(this).hasClass('video4') && !$(this).hasClass('gated') ) {
                $('.modals .modal-video4').css('left', currentMousePos.x);
                $('.modals .modal-video4').css('top', currentMousePos.y);

                setTimeout(function greet() {
                    $( ".modals .modal-video4" ).animate({left: '0px',top: "0px"}, 100, function() {
                        // Animation complete.
                      });
                    $('.modals .modal-video4').addClass('active');
                }, 100);
            };

            if ($(this).hasClass('video5') && !$(this).hasClass('gated') ) {
                $('.modals .modal-video5').css('left', currentMousePos.x);
                $('.modals .modal-video5').css('top', currentMousePos.y);

                setTimeout(function greet() {
                    $( ".modals .modal-video5" ).animate({left: '0px',top: "0px"}, 100, function() {
                        // Animation complete.
                      });
                    $('.modals .modal-video5').addClass('active');
                }, 100);
            };

            if ($(this).hasClass('video6') && !$(this).hasClass('gated') ) {
                $('.modals .modal-video6').css('left', currentMousePos.x);
                $('.modals .modal-video6').css('top', currentMousePos.y);

                setTimeout(function greet() {
                    $( ".modals .modal-video6" ).animate({left: '0px',top: "0px"}, 100, function() {
                        // Animation complete.
                      });
                    $('.modals .modal-video6').addClass('active');
                }, 100);
            };

            if ($(this).hasClass('gated')) {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: $('#formSection').offset().top
                }, 500);
            }
            
        });

        $('.video').on('click', function(event){
            if ($(this).hasClass('gated')) {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: $('#formSection').offset().top
                }, 500);
            }
        });



        $('.podcast1').on('click', function(){
            var currentMousePos = { x: -1, y: -1 };
                
            currentMousePos.x = event.clientX;
            currentMousePos.y = event.clientY;

            $('.modals .modal-podcast1').css('left', currentMousePos.x);
            $('.modals .modal-podcast1').css('top', currentMousePos.y);
            setTimeout(function podcast1() {

                var podcast1 =  videojs('podcast1',podcastParams, function() {
                    // print version information at startup
                    var msg = 'Using video.js '+ videojs.VERSION +
                        ' with videojs-wavesurfer ' +
                        videojs.getPluginVersion('wavesurfer') +
                        ' and wavesurfer.js ' + WaveSurfer.VERSION;
                    videojs.log(msg);

                    // load file
                    podcast1.src({src: 'images/adobe/Getting_started_on_your_CDP-1333410.mp3', type: 'audio/mp3'});
                });
            }, 400);
            setTimeout(function greet() {
                $( ".modals .modal-podcast1" ).animate({left: '0px',top: "0px"}, 100, function() {
                    // Animation complete.
                  });
                $('.modals .modal-podcast1').addClass('active');
            }, 100);

            if ($(this).hasClass('gated')) {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: $('#formSection').offset().top
                }, 500);
            }

        });

        $('.podcast2').on('click', function(){
            
            if (!$(this).hasClass('gated')) {
                var currentMousePos = { x: -1, y: -1 };
                
                currentMousePos.x = event.clientX;
                currentMousePos.y = event.clientY;

                $('.modals .modal-podcast2').css('left', currentMousePos.x);
                $('.modals .modal-podcast2').css('top', currentMousePos.y);
                setTimeout(function podcast2() {

                    var podcast2 =  videojs('podcast2',podcastParams2, function() {
                        // print version information at startup
                        var msg = 'Using video.js '+ videojs.VERSION +
                            ' with videojs-wavesurfer ' +
                            videojs.getPluginVersion('wavesurfer') +
                            ' and wavesurfer.js ' + WaveSurfer.VERSION;
                        videojs.log(msg);

                        // load file
                        podcast2.src({src: 'images/adobe/The_Six_Key_Steps_to_CDP_Succe-1333410.mp3', type: 'audio/mp3'});
                    });
                }, 400);
                setTimeout(function greet() {
                    $( ".modals .modal-podcast2" ).animate({left: '0px',top: "0px"}, 100, function() {
                        // Animation complete.
                      });
                    $('.modals .modal-podcast2').addClass('active');
                }, 100);
            }

            if ($(this).hasClass('gated')) {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: $('#formSection').offset().top
                }, 500);
            }
            
        });

        $('.podcast3').on('click', function(){

            if (!$(this).hasClass('gated')) {
                var currentMousePos = { x: -1, y: -1 };
                
                currentMousePos.x = event.clientX;
                currentMousePos.y = event.clientY;

                $('.modals .modal-podcast3').css('left', currentMousePos.x);
                $('.modals .modal-podcast3').css('top', currentMousePos.y);
                setTimeout(function podcast3() {
                var podcast3 =  videojs('podcast3',podcastParams3, function() {
                        // print version information at startup
                        var msg = 'Using video.js '+ videojs.VERSION +
                            ' with videojs-wavesurfer ' +
                            videojs.getPluginVersion('wavesurfer') +
                        ' and wavesurfer.js ' + WaveSurfer.VERSION;
                        videojs.log(msg);

                        // load file
                        podcast3.src({src: 'images/adobe/What_to_expect_in_the_future-1333410.mp3', type: 'audio/mp3'});
                    });
                }, 400);

                setTimeout(function greet() {
                    $( ".modals .modal-podcast3" ).animate({left: '0px',top: "0px"}, 100, function() {
                        // Animation complete.
                      });
                    $('.modals .modal-podcast3').addClass('active');
                }, 100);
            }

            if ($(this).hasClass('gated')) {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: $('#formSection').offset().top
                }, 500);
            }
            
        
        });

        $('.play').on('click', function(e){

            if ($(this).parent().parent().hasClass('video1')) {
                e.stopPropagation();
                player1.pause();
                $(this).toggleClass('pause');
                $(this).parent().parent().toggleClass('pause');
            } 
            if ($(this).parent().parent().hasClass('video2')) {
                e.stopPropagation();
                player2.pause();
                $(this).toggleClass('pause');
                $(this).parent().parent().toggleClass('pause');
            };
            if ($(this).parent().parent().hasClass('video3')) {
                e.stopPropagation();
                player3.pause();
                $(this).toggleClass('pause');
                $(this).parent().parent().toggleClass('pause');
            };
            if ($(this).parent().parent().hasClass('video4')) {
                e.stopPropagation();
                player4.pause();
                $(this).toggleClass('pause');
                $(this).parent().parent().toggleClass('pause');
            };
            if ($(this).parent().parent().hasClass('video5')) {
                e.stopPropagation();
                player5.pause();
                $(this).toggleClass('pause');
                $(this).parent().parent().toggleClass('pause');
            };
            // if ($(this).parent().parent().hasClass('video6')) {
            //     e.stopPropagation();
            //     player6.pause();
            //     $(this).toggleClass('pause');
            //     $(this).parent().parent().toggleClass('pause');
            // };

            if ($(this).hasClass('pause') && $(this).parent().parent().hasClass('video1')) {
                e.stopPropagation();
                player1.play();
                if (!$(this).parent().children('.unmute').hasClass('mute')) {
                    player1.muted(false);
                } else {
                    player1.muted(true);
                }
            }
            if ($(this).hasClass('pause') && $(this).parent().parent().hasClass('video2')) {
                e.stopPropagation();
                player2.play();
                if (!$(this).parent().children('.unmute').hasClass('mute')) {
                    player2.muted(false);
                } else {
                    player2.muted(true);
                }
            }
            if ($(this).hasClass('pause') && $(this).parent().parent().hasClass('video3')) {
                e.stopPropagation();
                player3.play();
                if (!$(this).parent().children('.unmute').hasClass('mute')) {
                    player3.muted(false);
                } else {
                    player3.muted(true);
                }
            }
            if ($(this).hasClass('pause') && $(this).parent().parent().hasClass('video4')) {
                e.stopPropagation();
                player4.play();
                if (!$(this).parent().children('.unmute').hasClass('mute')) {
                    player4.muted(false);
                } else {
                    player4.muted(true);
                }

            }
            if ($(this).hasClass('pause') && $(this).parent().parent().hasClass('video5')) {
                e.stopPropagation();
                player5.play();
                if (!$(this).parent().children('.unmute').hasClass('mute')) {
                    player5.muted(false);
                } else {
                    player5.muted(true);
                }

            }
            // if ($(this).hasClass('pause') && $(this).parent().parent().hasClass('video6')) {
            //     e.stopPropagation();
            //     player6.play();
            //     if (!$(this).parent().children('.unmute').hasClass('mute')) {
            //         player6.muted(false);
            //     } else {
            //         player6.muted(true);
            //     }
            // }
            
        });

        $('.unmute').on('click', function(e){
            if ($(this).parent().parent().hasClass('video1')) {
                e.stopPropagation();
                player1.muted(false);
                $(this).toggleClass('mute');
            } 
            if ($(this).parent().parent().hasClass('video2')) {
                e.stopPropagation();
                player2.muted(false);
                $(this).toggleClass('mute');
            };
            if ($(this).parent().parent().hasClass('video3')) {
                e.stopPropagation();
                player3.muted(false);
                $(this).toggleClass('mute');
            };
            if ($(this).parent().parent().hasClass('video4')) {
                e.stopPropagation();
                player4.muted(false);
                $(this).toggleClass('mute');
            };
            if ($(this).parent().parent().hasClass('video5')) {
                e.stopPropagation();
                player5.muted(false);
                $(this).toggleClass('mute');
            };
            // if ($(this).parent().parent().hasClass('video6')) {
            //     e.stopPropagation();
            //     player6.muted(false);
            //     $(this).toggleClass('mute');
            // };

            if ($(this).hasClass('mute') && $(this).parent().parent().hasClass('video1')) {
                e.stopPropagation();
                player1.muted(true);
            }
            if ($(this).hasClass('mute') && $(this).parent().parent().hasClass('video2')) {
                e.stopPropagation();
                player2.muted(true);
            }
            if ($(this).hasClass('mute') && $(this).parent().parent().hasClass('video3')) {
                e.stopPropagation();
                player3.muted(true);
            }
            if ($(this).hasClass('mute') && $(this).parent().parent().hasClass('video4')) {
                e.stopPropagation();
                player4.muted(true);
            }
            if ($(this).hasClass('mute') && $(this).parent().parent().hasClass('video5')) {
                e.stopPropagation();
                player5.muted(true);
            }
            // if ($(this).hasClass('mute') && $(this).parent().parent().hasClass('video6')) {
            //     e.stopPropagation();
            //     player6.muted(true);
            // }
        });


        $('.download').on('mouseover', function(){
            $(this).parent().parent().addClass('active');
        });
        $('.download').on('mouseout', function(){
            $(this).parent().parent().removeClass('active');
        });

    });

    $('.video').on('mouseover', function(){

        $(this).parent().parent().addClass('active');

        if ($(this).hasClass('video1') && !$(this).hasClass('pause')) { player1.play(); } 
        if ($(this).hasClass('video2') && !$(this).hasClass('pause') && !$(this).hasClass('gated')) { player2.play(); }
        if ($(this).hasClass('video3') && !$(this).hasClass('pause') && !$(this).hasClass('gated')) { player3.play(); }
        if ($(this).hasClass('video4') && !$(this).hasClass('pause') && !$(this).hasClass('gated')) { player4.play(); }
        if ($(this).hasClass('video5') && !$(this).hasClass('pause') && !$(this).hasClass('gated')) { player5.play(); }
        // if ($(this).hasClass('video6') && !$(this).hasClass('pause') && !$(this).hasClass('gated')) { player6.play(); }
    });

    $('.video').on('mouseout', function(){

        $(this).parent().parent().removeClass('active');

        if ($(this).hasClass('video1')) {
            player1.pause();
            if ($(this).children().children('.unmute').hasClass('mute')) { player1.muted(true); } 
            else { player1.muted(false); }
        } 
        if ($(this).hasClass('video2')) {
            player2.pause();
            if ($(this).children().children('.unmute').hasClass('mute')) { player2.muted(true); } 
            else { player2.muted(false); }
        }
        if ($(this).hasClass('video3')) {
            player3.pause();
            if ($(this).children().children('.unmute').hasClass('mute')) { player3.muted(true); } 
            else { player3.muted(false); }
        }
        if ($(this).hasClass('video4')) {
            player4.pause();
            if ($(this).children().children('.unmute').hasClass('mute')) { player4.muted(true); } 
            else { player4.muted(false); }
        }
        if ($(this).hasClass('video5')) {
            player5.pause();
            if ($(this).children().children('.unmute').hasClass('mute')) { player5.muted(true); } 
            else { player5.muted(false); }
        }
        // if ($(this).hasClass('video6')) {
        //     player6.pause();
        //     if ($(this).children().children('.unmute').hasClass('mute')) { player6.muted(true); } 
        //     else { player6.muted(false); }
        // }
    });

    $('.enlarge').on('click', function(event){
            var currentMousePos = { x: -1, y: -1 };   
            currentMousePos.x = event.clientX;
            currentMousePos.y = event.clientY;

            if ($(this).parent().parent().hasClass('video1')) {
                
                $('.modals .modal-video1').css('left', currentMousePos.x);
                $('.modals .modal-video1').css('top', currentMousePos.y);

                setTimeout(function greet() {
                    $( ".modals .modal-video1" ).animate({left: '0px',top: "0px"}, 100, function() {
                        // Animation complete.
                        });
                    $('.modals .modal-video1').addClass('active');
                }, 100);
            }


            // if ($(this).parent().parent().hasClass('video2') && !$(this).parent().parent().hasClass('gated') ) {
            //     $('.modals .modal-video2').css('left', currentMousePos.x);
            //     $('.modals .modal-video2').css('top', currentMousePos.y);

            //     setTimeout(function greet() {
            //         $( ".modals .modal-video2" ).animate({left: '0px',top: "0px"}, 100, function() {
            //             // Animation complete.
            //           });
            //         $('.modals .modal-video2').addClass('active');
            //     }, 100);
            // };

            // if ($(this).parent().parent().hasClass('video3') && !$(this).parent().parent().hasClass('gated') ) {
            //     $('.modals .modal-video3').css('left', currentMousePos.x);
            //     $('.modals .modal-video3').css('top', currentMousePos.y);

            //     setTimeout(function greet() {
            //         $( ".modals .modal-video3" ).animate({left: '0px',top: "0px"}, 100, function() {
            //             // Animation complete.
            //           });
            //         $('.modals .modal-video3').addClass('active');
            //     }, 100);
            // };
            
        });

    $('.download').on('click', function(event){
        var currentMousePos = { x: -1, y: -1 };   
        currentMousePos.x = event.clientX;
        currentMousePos.y = event.clientY;

        // if ($(this).hasClass('video4') && !$(this).hasClass('gated') ) {
        //     $('.modals .modal-video4').css('left', currentMousePos.x);
        //     $('.modals .modal-video4').css('top', currentMousePos.y);

        //     setTimeout(function greet() {
        //         $( ".modals .modal-video4" ).animate({left: '0px',top: "0px"}, 100, function() {
        //             // Animation complete.
        //           });
        //         $('.modals .modal-video4').addClass('active');
        //     }, 100);
        // };

        // if ($(this).hasClass('video5') && !$(this).hasClass('gated') ) {
        //     $('.modals .modal-video5').css('left', currentMousePos.x);
        //     $('.modals .modal-video5').css('top', currentMousePos.y);

        //     setTimeout(function greet() {
        //         $( ".modals .modal-video5" ).animate({left: '0px',top: "0px"}, 100, function() {
        //             // Animation complete.
        //           });
        //         $('.modals .modal-video5').addClass('active');
        //     }, 100);
        // };

        // if ($(this).hasClass('video6') && !$(this).hasClass('gated') ) {
        //     $('.modals .modal-video6').css('left', currentMousePos.x);
        //     $('.modals .modal-video6').css('top', currentMousePos.y);

        //     setTimeout(function greet() {
        //         $( ".modals .modal-video6" ).animate({left: '0px',top: "0px"}, 100, function() {
        //             // Animation complete.
        //           });
        //         $('.modals .modal-video6').addClass('active');
        //     }, 100);
        // };
        
        if ($(this).hasClass('gated')) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: $('#formSection').offset().top
            }, 500);
        }
    });

    $('.video').on('click', function(event){
        if ($(this).hasClass('gated')) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: $('#formSection').offset().top
            }, 500);
        }
    });

    $('.podcast1').on('click', function(){
        var currentMousePos = { x: -1, y: -1 };
            
        currentMousePos.x = event.clientX;
        currentMousePos.y = event.clientY;

        $('.modals .modal-podcast1').css('left', currentMousePos.x);
        $('.modals .modal-podcast1').css('top', currentMousePos.y);
        setTimeout(function podcast1() {

            var podcast1 =  videojs('podcast1',podcastParams, function() {
                // print version information at startup
                var msg = 'Using video.js '+ videojs.VERSION +
                    ' with videojs-wavesurfer ' +
                    videojs.getPluginVersion('wavesurfer') +
                    ' and wavesurfer.js ' + WaveSurfer.VERSION;
                videojs.log(msg);

                // load file
                podcast1.src({src: 'images/adobe/Getting_started_on_your_CDP-1333410.mp3', type: 'audio/mp3'});
            });
        }, 400);
        setTimeout(function greet() {
            $( ".modals .modal-podcast1" ).animate({left: '0px',top: "0px"}, 100, function() {
                // Animation complete.
              });
            $('.modals .modal-podcast1').addClass('active');
        }, 100);

    });

    $('.podcast2').on('click', function(){
        
        if (!$(this).hasClass('gated')) {
            var currentMousePos = { x: -1, y: -1 };
            
            currentMousePos.x = event.clientX;
            currentMousePos.y = event.clientY;

            $('.modals .modal-podcast2').css('left', currentMousePos.x);
            $('.modals .modal-podcast2').css('top', currentMousePos.y);
            setTimeout(function podcast2() {

                var podcast2 =  videojs('podcast2',podcastParams2, function() {
                    // print version information at startup
                    var msg = 'Using video.js '+ videojs.VERSION +
                        ' with videojs-wavesurfer ' +
                        videojs.getPluginVersion('wavesurfer') +
                        ' and wavesurfer.js ' + WaveSurfer.VERSION;
                    videojs.log(msg);

                    // load file
                    podcast2.src({src: 'images/adobe/The_Six_Key_Steps_to_CDP_Succe-1333410.mp3', type: 'audio/mp3'});
                });
            }, 400);
            setTimeout(function greet() {
                $( ".modals .modal-podcast2" ).animate({left: '0px',top: "0px"}, 100, function() {
                    // Animation complete.
                  });
                $('.modals .modal-podcast2').addClass('active');
            }, 100);
        }
        
    });

    $('.podcast3').on('click', function(){

        if (!$(this).hasClass('gated')) {
            var currentMousePos = { x: -1, y: -1 };
            
            currentMousePos.x = event.clientX;
            currentMousePos.y = event.clientY;

            $('.modals .modal-podcast3').css('left', currentMousePos.x);
            $('.modals .modal-podcast3').css('top', currentMousePos.y);
            setTimeout(function podcast3() {
            var podcast3 =  videojs('podcast3',podcastParams3, function() {
                    // print version information at startup
                    var msg = 'Using video.js '+ videojs.VERSION +
                        ' with videojs-wavesurfer ' +
                        videojs.getPluginVersion('wavesurfer') +
                    ' and wavesurfer.js ' + WaveSurfer.VERSION;
                    videojs.log(msg);

                    // load file
                    podcast3.src({src: 'images/adobe/What_to_expect_in_the_future-1333410.mp3', type: 'audio/mp3'});
                });
            }, 400);

            setTimeout(function greet() {
                $( ".modals .modal-podcast3" ).animate({left: '0px',top: "0px"}, 100, function() {
                    // Animation complete.
                  });
                $('.modals .modal-podcast3').addClass('active');
            }, 100);
        }
        
    
    });



    $('.play').on('click', function(e){

        if ($(this).parent().parent().hasClass('video1')) {
            e.stopPropagation();
            player1.pause();
            $(this).toggleClass('pause');
            $(this).parent().parent().toggleClass('pause');
        } 
        if ($(this).parent().parent().hasClass('video2')) {
            e.stopPropagation();
            player2.pause();
            $(this).toggleClass('pause');
            $(this).parent().parent().toggleClass('pause');
        };
        if ($(this).parent().parent().hasClass('video3')) {
            e.stopPropagation();
            player3.pause();
            $(this).toggleClass('pause');
            $(this).parent().parent().toggleClass('pause');
        };
        if ($(this).parent().parent().hasClass('video4')) {
            e.stopPropagation();
            player4.pause();
            $(this).toggleClass('pause');
            $(this).parent().parent().toggleClass('pause');
        };
        if ($(this).parent().parent().hasClass('video5')) {
            e.stopPropagation();
            player5.pause();
            $(this).toggleClass('pause');
            $(this).parent().parent().toggleClass('pause');
        };
        // if ($(this).parent().parent().hasClass('video6')) {
        //     e.stopPropagation();
        //     player6.pause();
        //     $(this).toggleClass('pause');
        //     $(this).parent().parent().toggleClass('pause');
        // };

        if ($(this).hasClass('pause') && $(this).parent().parent().hasClass('video1')) {
            e.stopPropagation();
            player1.play();
            if (!$(this).parent().children('.unmute').hasClass('mute')) {
                player1.muted(false);
            } else {
                player1.muted(true);
            }
        }
        if ($(this).hasClass('pause') && $(this).parent().parent().hasClass('video2')) {
            e.stopPropagation();
            player2.play();
            if (!$(this).parent().children('.unmute').hasClass('mute')) {
                player2.muted(false);
            } else {
                player2.muted(true);
            }
        }
        if ($(this).hasClass('pause') && $(this).parent().parent().hasClass('video3')) {
            e.stopPropagation();
            player3.play();
            if (!$(this).parent().children('.unmute').hasClass('mute')) {
                player3.muted(false);
            } else {
                player3.muted(true);
            }
        }
        if ($(this).hasClass('pause') && $(this).parent().parent().hasClass('video4')) {
            e.stopPropagation();
            player4.play();
            if (!$(this).parent().children('.unmute').hasClass('mute')) {
                player4.muted(false);
            } else {
                player4.muted(true);
            }

        }
        if ($(this).hasClass('pause') && $(this).parent().parent().hasClass('video5')) {
            e.stopPropagation();
            player5.play();
            if (!$(this).parent().children('.unmute').hasClass('mute')) {
                player5.muted(false);
            } else {
                player5.muted(true);
            }

        }
        // if ($(this).hasClass('pause') && $(this).parent().parent().hasClass('video6')) {
        //     e.stopPropagation();
        //     player6.play();
        //     if (!$(this).parent().children('.unmute').hasClass('mute')) {
        //         player6.muted(false);
        //     } else {
        //         player6.muted(true);
        //     }
        // }
        
    });

    $('.unmute').on('click', function(e){
        if ($(this).parent().parent().hasClass('video1')) {
            e.stopPropagation();
            player1.muted(false);
            $(this).toggleClass('mute');
        } 
        if ($(this).parent().parent().hasClass('video2')) {
            e.stopPropagation();
            player2.muted(false);
            $(this).toggleClass('mute');
        };
        if ($(this).parent().parent().hasClass('video3')) {
            e.stopPropagation();
            player3.muted(false);
            $(this).toggleClass('mute');
        };
        if ($(this).parent().parent().hasClass('video4')) {
            e.stopPropagation();
            player4.muted(false);
            $(this).toggleClass('mute');
        };
        if ($(this).parent().parent().hasClass('video5')) {
            e.stopPropagation();
            player5.muted(false);
            $(this).toggleClass('mute');
        };
        // if ($(this).parent().parent().hasClass('video6')) {
        //     e.stopPropagation();
        //     player6.muted(false);
        //     $(this).toggleClass('mute');
        // };

        if ($(this).hasClass('mute') && $(this).parent().parent().hasClass('video1')) {
            e.stopPropagation();
            player1.muted(true);
        }
        if ($(this).hasClass('mute') && $(this).parent().parent().hasClass('video2')) {
            e.stopPropagation();
            player2.muted(true);
        }
        if ($(this).hasClass('mute') && $(this).parent().parent().hasClass('video3')) {
            e.stopPropagation();
            player3.muted(true);
        }
        if ($(this).hasClass('mute') && $(this).parent().parent().hasClass('video4')) {
            e.stopPropagation();
            player4.muted(true);
        }
        if ($(this).hasClass('mute') && $(this).parent().parent().hasClass('video5')) {
            e.stopPropagation();
            player5.muted(true);
        }
        // if ($(this).hasClass('mute') && $(this).parent().parent().hasClass('video6')) {
        //     e.stopPropagation();
        //     player6.muted(true);
        // }
    });


    $('.download').on('mouseover', function(){
        $(this).parent().parent().addClass('active');
    });
    $('.download').on('mouseout', function(){
        $(this).parent().parent().removeClass('active');
    });

    $('.close').on('click', function(){
        if ($(this).parent().hasClass('modal-video1')) {
           $('.modals .modal-video1').removeClass('active');
           $('.modals .modal-video1').attr('style', '');
           player1a.pause(); 
        }
        if ($(this).parent().hasClass('modal-podcast1')) {
            $('.modals .modal-podcast1').removeClass('active');
            $('.modals .modal-podcast1').attr('style', '');

            videojs('podcast1').pause();
            
        }
        if ($(this).parent().hasClass('modal-podcast2')) {
            $('.modals .modal-podcast2').removeClass('active');
            $('.modals .modal-podcast2').attr('style', '');
            videojs('podcast2').pause();
        }
        if ($(this).parent().hasClass('modal-podcast3')) {
            $('.modals .modal-podcast3').removeClass('active');
            $('.modals .modal-podcast3').attr('style', '');
            videojs('podcast3').pause();
            
        }
        if ($(this).parent().hasClass('modal-video2')) {
           $('.modals .modal-video2').removeClass('active');
           $('.modals .modal-video2').attr('style', '');
           player2a.pause(); 
        }
        if ($(this).parent().hasClass('modal-video3')) {
           $('.modals .modal-video3').removeClass('active');
           $('.modals .modal-video3').attr('style', '');
           player3a.pause(); 
        }
        if ($(this).parent().hasClass('modal-video4')) {
           $('.modals .modal-video4').removeClass('active');
           $('.modals .modal-video4').attr('style', '');
           player4a.pause(); 
        }
        if ($(this).parent().hasClass('modal-video5')) {
           $('.modals .modal-video5').removeClass('active');
           $('.modals .modal-video5').attr('style', '');
           player5a.pause(); 
        }
        // if ($(this).parent().hasClass('modal-video6')) {
        //    $('.modals .modal-video6').removeClass('active');
        //    $('.modals .modal-video6').attr('style', '');
        //    player6a.pause(); 
        // }

    });

    

    $(window).resize(function() {

        var winWidth = $(window).width();

        var videoContainerWidth = $('.modal .container .embed-container').width();
        var videoContainerHeight = $('.modal .container').height();

        console.log(videoContainerWidth);


        $('.vjs-wavesurfer.vjs-fluid wave.vjs-wavedisplay canvas').width(videoContainerWidth);
        $('.vjs-wavesurfer.vjs-fluid wave.vjs-wavedisplay canvas').height(videoContainerHeight);

        var mastheadHeight = $('.masthead3').outerHeight();
        $('header').css('margin-top', mastheadHeight);

        if (winWidth > 767) {
            
        }
        if (winWidth <= 766) {
            
        }
    });

    var scrollTopSection2 = $('.section2').offset().top;

    console.log(scrollTopSection2);

    $(window).on('scroll', function(){

        

    });




});
 

},{}]},{},[1]);
